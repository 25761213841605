/* -------------------------------- R E A D I N G   R O O M ---------------------------------------- */

.reading-room-general-container {
  /* border: 1px solid red; */
  padding: 6rem 5rem 5rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.reading-room-title {
  /* border: 1px solid yellow; */
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  text-transform: uppercase;
  margin-bottom: 5rem;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: .9;
}


.reading-room-text {
  /* border: 1px solid yellow; */
  color: var(--primary-text-color);
  text-align: justify;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  /* word-spacing: .1rem; */
}

/* p::first-letter {
  visibility: hidden;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(255, 200, 177);
} */

/* -------------------------------------- R E A D I N G   R O O M   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- R E A D I N G   R O O M   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- R E A D I N G   R O O M   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- R E A D I N G   R O O M   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- R E A D I N G   R O O M   R E S P O N S I V E N E S S ------------------------------------------ */

@media screen and (min-width: 1800px) {
  .reading-room-title {
    font-size: 2.35rem;
  }

  .reading-room-text {
    font-size: 1.15rem;
  }
}

@media screen and (max-width: 800px) {
  .reading-room-general-container {
    /* border: 1px solid red; */
    padding: 5rem 3rem 3rem 3rem;
  }

  .reading-room-title {
    /* border: 1px solid yellow; */
    margin-bottom: 4rem;
  }
}


@media screen and (max-width: 500px) {
  .reading-room-general-container {
    /* border: 1px solid red; */
    padding: 4rem 1rem 3rem 1rem;
  }

  .reading-room-title {
    /* border: 1px solid yellow; */
    margin-bottom: 3rem;
  }
}

/* ---------------------------------------- O T H E R W I S E   I F   P A G E   L O A D I N G -------------------------------------- */
/* ---------------------------------------- O T H E R W I S E   I F   P A G E   L O A D I N G -------------------------------------- */
/* ---------------------------------------- O T H E R W I S E   I F   P A G E   L O A D I N G -------------------------------------- */



.reading-room-loading {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
  color: var(--primary-text-color);
  font-size: 60px;
  font-weight: 900;

  --hop-height: 50px;
}

.reading-room-loading-percentage {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  color: var(--primary-text-color);
  font-size: 50px;
  font-weight: 900;
}

.reading-room-loading-bar-container {
  border: 1px solid var(--primary-text-color);
  border-radius: 10px;
  width: 50%;
  margin: auto;
  padding: 1px 1.5px 1.5px 1.5px;
}

.reading-room-loading-bar {
  border-radius: 10px;
  background-color: var(--primary-text-color);
  height: 10px;
  width: 0;
  animation: none;
}

@keyframes loadingAnimation {
  to {
    width: 100%;
  }
}



@keyframes hop {

  0%,
  100% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(var(--hop-height))
  }
}

/* Add animation for each letter with a delay */
.reading-room-loading span:nth-child(1) {
  animation: hop 1.5s infinite
}

.reading-room-loading span:nth-child(2) {
  animation: hop 1.5s infinite 0.25s
}

.reading-room-loading span:nth-child(3) {
  animation: hop 1.5s infinite 0.5s
}

.reading-room-loading span:nth-child(4) {
  animation: hop 1.5s infinite 0.75s
}

.reading-room-loading span:nth-child(5) {
  animation: hop 1.5s infinite 1s
}

.reading-room-loading span:nth-child(6) {
  animation: hop 1.5s infinite 1.25s
}

.reading-room-loading span:nth-child(7) {
  animation: hop 1.5s infinite 1.5s
}

.reading-room-loading span:nth-child(8) {
  animation: hop 1.5s infinite 1.75s
}

.reading-room-loading span:nth-child(9) {
  animation: hop 1.5s infinite 2s
}

.reading-room-loading span:nth-child(10) {
  animation: hop 1.5s infinite 2.25s
}


/* -------------------------------------- L O A D I N G   P A G E   R E S P O N S I V N E S S -------------------------------------- */
/* -------------------------------------- L O A D I N G   P A G E   R E S P O N S I V N E S S -------------------------------------- */
/* -------------------------------------- L O A D I N G   P A G E   R E S P O N S I V N E S S -------------------------------------- */

@media screen and (max-width: 1200px) {
  .reading-room-loading-bar-container {
    width: 65%;
  }
}

@media screen and (max-width: 900px) {
  .reading-room-loading-bar-container {
    width: 80%;
  }
}

@media screen and (max-width: 800px) {
  .reading-room-loading {
    margin-top: 100px;
    padding-bottom: 40px;
    font-size: 40px;
    --hop-height: 40px;
  }

  .reading-room-loading-percentage {
    font-size: 30px;
  }
}


@media screen and (max-width: 500px) {
  .reading-room-loading {
    padding-bottom: 20px;
    font-size: 25px;
    --hop-height: 20px;
  }

  .reading-room-loading-percentage {
    font-size: 20px;
  }

  .reading-room-loading-bar-container {
    width: 70%;
  }
}

@media screen and (max-width: 400px) {
  .reading-room-loading-bar-container {
    width: 85%;
  }
}

@media screen and (max-width: 300px) {
  .reading-room-loading-bar-container {
    padding: 1px 1.5px 1px 1.5px;
  }
}
