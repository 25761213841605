.footer-scroll-to-top-container {
    /* border: 3px solid green; */
    display: flex;
    /* width: 97.5%; */
    justify-content: flex-end;
    padding-right: .6%
}

.footer-scroll-to-top {
    /* border: 1px solid red; */
    display: flex;
    color: var(--primary-text-color);
    font-size: 3em;
    border-radius: 25px;
    transition: 0.3s ease;
}

.footer-scroll-to-top:hover {
    cursor: pointer;
    transition: 0.3s ease;
    color: #d0539a;
}