/* ----------------------------------- S I D E S T O R I E S   P A G E ------------------------------------------- */

.sidestories-general-container {
  /* border: 5px solid red; */
  padding: 6rem 4rem 4rem 4rem;
}

.sidestories-main-title {
  /* border: 1px solid green; */
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  margin-bottom: 6rem;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: .9;
}

.all-sidestories-wrapper {
  /* border: 3px solid rgb(182, 242, 30); */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4em;
  text-align: center;
}

.single-sidestory-wrapper {
  /* border: 1px solid rgb(243, 66, 243); */
  /* position: relative; */
  background-color: var(--text-background-color);
  color: var(--secondary-text-color);
  box-shadow: var(--containers-box-shadow);
  border-radius: 15px;
  padding: 3% 6% 3% 6%;
  display: flex;
  flex-direction: column;
}

.sidestory-title {
  font-family: var(--titles-font-family);
  font-size: 1.35rem;
  font-weight: 900;
  margin-bottom: 5%;
}

.sidestory-brief {
  text-align: justify;
  margin-bottom: 5%;
  font-size: 1.05rem;
  font-weight: 600;
}

.sidestory-button {
  /* border: 1px solid #220617; */
  color: var(--secondary-text-color);

  text-decoration: none;
  font-size: 1.05rem;
  font-weight: 600;
  border-radius: 4px;
  padding: 3px;
  margin-top: auto;
}

.sidestory-button:hover {
  text-decoration: underline;
}


/* -------------------------------------- S I D E S T O R Y   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- S I D E S T O R Y   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- S I D E S T O R Y   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- S I D E S T O R Y   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- S I D E S T O R Y   R E S P O N S I V E N E S S ------------------------------------------ */

@media screen and (min-width: 1800px) {
  .sidestories-main-title {
    font-size: 2.35rem;
  }

  .sidestory-title {
    font-size: 1.45rem;
  }

  .sidestory-brief {
    font-size: 1.15rem;
  }

  .sidestory-button {
    font-size: 1.15rem;
  }
}

@media screen and (min-width: 1720px) {
  .sidestories-general-container {
    padding: 6rem 6rem 4rem 6rem;
  }

  .all-sidestories-wrapper {
    gap: 6em;
  }
}

@media screen and (max-width: 1200px) {

  .sidestories-general-container {
    padding: 6rem 6rem 6rem 6rem;
  }

  .sidestories-main-title {
    margin-bottom: 6rem;
  }

  .all-sidestories-wrapper {
    grid-template-columns: repeat(2, 1fr);
    gap: 5em;
  }

  .single-sidestory-wrapper {
    padding: 4% 8% 4% 8%;
  }
}

@media screen and (max-width: 975px) {
  .sidestories-general-container {
    padding: 4.5rem 5rem 4.5rem 5rem;
  }

  .sidestories-main-title {
    margin-bottom: 4.5rem;
    font-size: 1.6rem;
  }

  .sidestory-title {
    font-size: 1.03rem;
  }

  .sidestory-brief {
    font-size: .95rem;
  }
}

@media screen and (max-width: 950px) {

  .sidestories-general-container {
    padding: 4.5rem 4rem 4.5rem 4rem;
  }

  .sidestories-main-title {
    margin-bottom: 4.5rem;
  }

  .all-sidestories-wrapper {
    gap: 4em;
  }

  .single-sidestory-wrapper {
    padding: 4% 8% 4% 8%;
  }
}

@media screen and (max-width: 750px) {
  .sidestories-general-container {
    padding: 4.5rem 5rem 4.5rem 5rem;
  }

  .sidestories-main-title {
    margin-bottom: 4.5rem;
  }

  .all-sidestories-wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 5em;
  }

  .single-sidestory-wrapper {
    padding: 3% 6% 3% 6%;
  }
}

@media screen and (max-width: 530px) {
  .sidestories-general-container {
    padding: 3.5rem 2.5rem 3.5rem 2.5rem;
  }

  .sidestories-main-title {
    margin-bottom: 3.5rem;
  }

  .all-sidestories-wrapper {
    gap: 3rem;
  }

  .single-sidestory-wrapper {
    padding: 3% 6% 3% 6%;
  }

  .sidestories-main-title {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 400px) {
  .sidestories-general-container {
    padding: 3rem 2rem 3rem 2rem;
  }

  .sidestories-main-title {
    margin-bottom: 3rem;
  }

  .all-sidestories-wrapper {
    gap: 3rem;
  }

  .single-sidestory-wrapper {
    padding: 3% 6% 3% 6%;
  }

  .sidestories-main-title {
    font-size: 1.3rem;
  }

  .sidestory-title {
    font-size: .95rem;
  }

  .sidestory-brief {
    font-size: .85rem;
  }

  .sidestory-button {
    font-size: .85rem
  }
}


/* ---------------------------------------- O T H E R W I S E   I F   P A G E   L O A D I N G -------------------------------------- */
/* ---------------------------------------- O T H E R W I S E   I F   P A G E   L O A D I N G -------------------------------------- */
/* ---------------------------------------- O T H E R W I S E   I F   P A G E   L O A D I N G -------------------------------------- */

.sidestory-page-loading {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 50px;
  color: var(--primary-text-color);
  font-size: 60px;
  font-weight: 900;

  --hop-height: 50px;
}

@keyframes hop {

  0%,
  100% {
    transform: translateY(0)
  }

  50% {
    transform: translateY(var(--hop-height))
  }
}

.sidestory-page-loading-percentage {
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  color: var(--primary-text-color);
  font-size: 50px;
  font-weight: 900;
}

.sidestory-page-loading-bar-container {
  border: 1px solid var(--primary-text-color);
  border-radius: 10px;
  width: 50%;
  margin: auto;
  padding: 1px 1.5px 1.5px 1.5px;
}

.sidestory-page-loading-bar {
  border-radius: 10px;
  background-color: var(--primary-text-color);
  height: 10px;
  width: 0;
  animation: none;
}

@keyframes loadingAnimation {
  to {
    width: 100%;
  }
}





/* Add animation for each letter with a delay */
.sidestory-page-loading span:nth-child(1) {
  animation: hop 1.5s infinite
}

.sidestory-page-loading span:nth-child(2) {
  animation: hop 1.5s infinite 0.25s
}

.sidestory-page-loading span:nth-child(3) {
  animation: hop 1.5s infinite 0.5s
}

.sidestory-page-loading span:nth-child(4) {
  animation: hop 1.5s infinite 0.75s
}

.sidestory-page-loading span:nth-child(5) {
  animation: hop 1.5s infinite 1s
}

.sidestory-page-loading span:nth-child(6) {
  animation: hop 1.5s infinite 1.25s
}

.sidestory-page-loading span:nth-child(7) {
  animation: hop 1.5s infinite 1.5s
}

.sidestory-page-loading span:nth-child(8) {
  animation: hop 1.5s infinite 1.75s
}

.sidestory-page-loading span:nth-child(9) {
  animation: hop 1.5s infinite 2s
}

.sidestory-page-loading span:nth-child(10) {
  animation: hop 1.5s infinite 2.25s
}


/* -------------------------------------- L O A D I N G   P A G E   R E S P O N S I V N E S S -------------------------------------- */
/* -------------------------------------- L O A D I N G   P A G E   R E S P O N S I V N E S S -------------------------------------- */
/* -------------------------------------- L O A D I N G   P A G E   R E S P O N S I V N E S S -------------------------------------- */

@media screen and (max-width: 1200px) {
  .sidestory-page-loading-bar-container {
    width: 70%;
  }
}

@media screen and (max-width: 800px) {
  .sidestory-page-loading {
    margin-top: 100px;
    padding-bottom: 40px;
    font-size: 40px;
    --hop-height: 40px;
  }

  .sidestory-page-loading-percentage {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  .sidestory-page-loading-bar-container {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .sidestory-page-loading {
    padding-bottom: 20px;
    font-size: 25px;
    --hop-height: 20px;
  }

  .sidestory-page-loading-percentage {
    font-size: 20px;
  }

  .sidestory-page-loading-bar-container {
    width: 80%;
  }
}

@media screen and (max-width: 400px) {
  .sidestory-page-loading-bar-container {
    width: 100%;
  }
}