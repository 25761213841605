.book-general-container {
  /* border: 5px solid red; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 6rem 6rem 6rem 6rem;
  gap: 6em;
}

.book1-main-title {
  /* border: 1px solid green; */
  grid-column: span 2;
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  font-size: 2.2rem;
  font-weight: 900;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  line-height: .9;
}

.book2-left-container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.book-title {
  /* border: 1px solid green; */
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  font-size: 1.35rem;
  font-weight: 900;
  text-align: center;
  text-decoration: underline;
}

.book-text {
  /* border: 1px solid green; */
  color: var(--primary-text-color);
  /* grid-column-start: 4;
    grid-column-end: 5;
    grid-row-start: 3;
    grid-row-end: 4; */
  padding-top: 3%;
  font-size: 1.05rem;
  font-weight: 600;
}

.book3-release-date-container {
  /* border: 1px solid green; */
  grid-column-start: 4;
  grid-column-end: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.book3-right-container-image {
  /* border: 1px solid green; */
  background-image: url("../../../public/images/book-cover.png");
  background-repeat: no-repeat;
  background-position: 52% 0px;
  background-size: contain;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  max-height: 460px;
  min-width: 100px;
}

.book-image-title {
  font-size: .85rem;
  font-weight: 900;
  padding: 45px 0px 0px 0px;
  color: rgb(25, 25, 25);
}

.book-image-questionmark {
  font-weight: 300;
  font-size: 12rem;
  padding: 0px 20px 0px 0px;
  color: rgb(25, 25, 25);
}

.book-image-countdown {
  font-size: 1.6rem;
  font-weight: 700;
  color: rgb(25, 25, 25);
}


/* ------------------------------------------ B O O K   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ B O O K   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ B O O K   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ B O O K   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ B O O K   R E S P O N S I V E N E S S ---------------------------------------------- */

@media screen and (min-width: 1800px) {

  .book1-main-title {
    font-size: 2.35rem;
  }

  .book-title {
    font-size: 1.45rem;
  }

  .book-text {
    font-size: 1.15rem;
  }

  .book3-right-container-image {
    height: 500px;
  }
}

@media screen and (max-width: 1150px) {
  .book-general-container {
    padding: 6rem 14rem 6rem 14rem;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 6rem 9rem
  }

  .book1-main-title {
    grid-column: span 1;
  }

  .book2-left-container {
    grid-row-start: 2;
  }

  .book3-right-container-image {
    min-height: 450px;
    max-height: 450px;
  }
}

@media screen and (max-width: 975px) {
  .book-general-container {
    padding: 4.5rem 4.5rem 4.5rem 4.5rem;
    gap: 4.5rem;
  }

  .book1-main-title {
    font-size: 1.6rem;
  }

  .book-title {
    font-size: 1.03rem;
  }

  .book-text {
    font-size: .95rem;
  }
}

@media screen and (max-width: 530px) {
  .book-general-container {
    padding: 3.5rem 4.5rem 3.5rem 4.5rem;
    gap: 3.5rem;
  }

  .book1-main-title {
    font-size: 1.6rem;
  }

  .book-title {
    font-size: 1.03rem;
  }

  .book-text {
    font-size: .95rem;
  }

  .book3-right-container-image {
    min-height: 400px;
    max-height: 450px;
  }
}

@media screen and (max-width: 400px) {
  .book-general-container {
    padding: 3rem 2rem 3rem 2rem;
    gap: 3rem;
  }

  .book1-main-title {
    font-size: 1.3rem;
  }

  .book-title {
    font-size: .95rem;
  }

  .book-text {
    font-size: .85rem;
  }
}

@media screen and (max-width: 300px) {
  .book3-right-container-image {
    min-height: 300px;
    max-height: 450px;
  }
}