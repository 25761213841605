/* ------------------------------------------ G E M S T O N E S   G U I D E   P A G E ---------------------------------------------- */

.gemstones-guide-general-container {
  /* border: 5px solid red; */
  padding: 6rem 5rem 5rem 5rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6rem 6rem;
}

.gemstones-guide-main-title {
  /* border: 1px solid green; */
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  grid-column: span 3;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: .9;
}

.gemstone-container {
  background-color: var(--text-background-color);
  color: var(--secondary-text-color);
  box-shadow: var(--containers-box-shadow);
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  border-radius: 15px;
  padding: 5% 10% 10% 10%;
}

.gemstone-title {
  /* border: 1px solid red; */
  font-family: var(--titles-font-family);
  font-size: 1.35rem;
  font-weight: 900;
  margin-bottom: 6%;
}

.gemstone-image {
  /* border: 1px solid red; */
  margin-bottom: 5%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 30%;
  height: 90px;
}

.gemstone1-image {
  background-image: url("../../../public/images/Tourmaline.png");
}

.gemstone2-image {
  background-image: url("../../../public/images/Andalusite.png");
  width: 30%;
}

.gemstone3-image {
  background-image: url("../../../public/images/Olivine.png");
  width: 30%;
}

.gemstone4-image {
  background-image: url("../../../public/images/Agate.png");
  width: 28%;
}

.gemstone5-image {
  background-image: url("../../../public/images/Garnet.png");
  background-size: cover;
  width: 35%;
}

.gemstone6-image {
  background-image: url("../../../public/images/Lapis-Lazuli.png");
  background-size: cover;
  width: 30%;
}

.gemstone7-image {
  background-image: url("../../../public/images/Feldspar.png");
  width: 30%;
}

.gemstone8-image {
  background-image: url("../../../public/images/Zircon.png");
  background-size: cover;
  width: 30%;
}

.gemstone9-image {
  background-image: url("../../../public/images/Diamond.png");
  background-size: cover;
  width: 30%;
}

.gemstone-text {
  font-size: 1.05rem;
  font-weight: 600;
}


/* -------------------------------------- G E M S T O N E S   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- G E M S T O N E S   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- G E M S T O N E S   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- G E M S T O N E S   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- G E M S T O N E S   R E S P O N S I V E N E S S ------------------------------------------ */

@media screen and (min-width: 1800px) {
  .gemstones-guide-main-title {
    font-size: 2.35rem;
  }

  .gemstone-title {
    font-size: 1.45rem;
  }

  .gemstone-text {
    font-size: 1.15rem;
  }
}

@media screen and (min-width: 1640px) {
  .gemstone5-image {
    width: 32%;
  }

  .gemstone6-image {
    width: 27%;
  }

  .gemstone8-image {
    width: 27%;
  }
}

@media screen and (min-width: 1760px) {
  .gemstone5-image {
    width: 29%;
  }

  .gemstone6-image {
    width: 24%;
  }

  .gemstone8-image {
    width: 25%;
  }

  .gemstone9-image {
    width: 27%;
  }
}

@media screen and (max-width: 1400px) {
  .gemstone6-image {
    width: 34%;
  }

  .gemstone9-image {
    width: 34%;
  }
}

@media screen and (max-width: 1270px) {
  .gemstone9-image {
    width: 40%;
  }
}

@media screen and (max-width: 1200px) {

  .gemstones-guide-general-container {
    padding: 6rem 5rem 6rem 5rem;
    grid-template-columns: repeat(2, 1fr);
    gap: 6rem 5rem;
  }

  .gemstones-guide-main-title {
    grid-column: span 2;
  }

  .gemstone5-image {
    height: 105px;
    width: 30%;
  }

  .gemstone6-image {
    width: 27%;
    height: 100px;
  }

  .gemstone8-image {
    /* height: 100px; */
    width: 25%;
  }

  .gemstone9-image {
    width: 27%;
  }
}

@media screen and (max-width: 1100px) {
  .gemstone6-image {
    width: 30%;
  }
}

@media screen and (max-width: 975px) {
  .gemstones-guide-general-container {
    padding: 4.5rem 4rem 4.5rem 4rem;
    gap: 4.5rem 4rem;
  }

  .gemstones-guide-main-title {
    font-size: 1.6rem;
  }

  .gemstone-title {
    font-size: 1.03rem;
  }

  .gemstone-text {
    font-size: .95rem;
  }

  .gemstone6-image {
    width: 34%;
  }

  .gemstone8-image {
    /* height: 100px; */
    width: 30%;
  }

  .gemstone9-image {
    width: 32%;
  }
}

@media screen and (max-width: 850px) {
  .gemstone5-image {
    width: 40%;
  }

  .gemstone6-image {
    width: 40%;
  }

  .gemstone9-image {
    width: 40%;
  }
}


@media screen and (max-width: 750px) {
  .gemstones-guide-general-container {
    padding: 4.5rem 4rem 4rem 4rem;
    grid-template-columns: repeat(1, 1fr);
    gap: 4.5rem 4rem;
  }

  .gemstones-guide-main-title {
    grid-column: span 1;
  }

  .gemstone-container {
    padding: 4% 6% 6% 6%;
  }

  .gemstone5-image {
    width: 22%;
  }

  .gemstone6-image {
    width: 21%;
  }

  .gemstone8-image {
    width: 18%;
  }

  .gemstone9-image {
    width: 20%;
  }

}

@media screen and (max-width: 610px) {
  .gemstone6-image {
    width: 25%;
  }

  .gemstone9-image {
    width: 26%;
  }
}

@media screen and (max-width: 550px) {
  .gemstones-guide-general-container {
    padding: 3.5rem 3rem 3.5rem 3rem;
    gap: 3.5rem 3rem;
  }

  .gemstones-guide-main-title {
    font-size: 1.6rem;
  }

  .gemstone5-image {
    width: 35%;
  }

  .gemstone8-image {
    /* height: 100px; */
    width: 25%;
  }
}

@media screen and (max-width: 500px) {
  .gemstone6-image {
    width: 31%;
  }

  .gemstone9-image {
    width: 30%;
  }
}

@media screen and (max-width: 420px) {
  .gemstones-guide-general-container {
    padding: 3rem 2rem 2rem 2rem;
    gap: 3rem 1.5rem;
  }

  .gemstones-guide-main-title {
    font-size: 1.3rem;
  }

  .gemstone-title {
    font-size: .95rem;
  }

  .gemstone-text {
    font-size: .85rem;
  }

  .gemstone5-image {
    width: 40%;
  }

  .gemstone6-image {
    width: 35%;
  }

  .gemstone8-image {
    /* height: 100px; */
    width: 30%;
  }

  .gemstone9-image {
    width: 32%;
  }
}

@media screen and (max-width: 360px) {
  .gemstone6-image {
    width: 40%;
  }

  .gemstone8-image {
    /* height: 100px; */
    width: 40%;
  }

  .gemstone9-image {
    width: 37%;
  }
}