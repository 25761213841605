@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  box-sizing: border-box;
  /* color: rgb(206, 115, 115) !important; */
  /* cursor: none; */
}

/* top empty space div on App.js to even out fixed navbar top space  */
.top-empty-space {
  /* border: 1px solid green; */
  height: 55px;
  z-index: -1;
}

@media screen and (max-width: 800px) {
  .top-empty-space {
    height: 52px;
  }
}

@media screen and (max-width: 700px) {
  .top-empty-space {
    height: 49px;
  }
}

@media screen and (max-width: 300px) {
  .top-empty-space {
    height: 92px;
  }
}

/* ---------------------------- L I G H T   /   D A R K   M O D E ---------------------------------*/
/* ---------------------------- L I G H T   /   D A R K   M O D E ---------------------------------*/
/* ---------------------------- L I G H T   /   D A R K   M O D E ---------------------------------*/

/* :root {
  --background: white;
  --text-primary: black;
  --text-secondary: royalblue;
  --accent: purple;
  --transition: 0.5s ease;
} */

[data-theme='light'] {
  --general-background: white;
  --primary-text-color: #420a2b;
  --secondary-text-color: rgba(255, 255, 255, 0.9);
  --text-background-color: #420a2b;
  --navbar-footer-text-color: rgba(255, 255, 255, 0.9);
  --containers-box-shadow: 0px 0px 35px 0px rgb(70, 70, 70);
  --titles-font-family: "Poppins";
  --transition: 0.5s ease;
}

[data-theme='dark'] {
  --general-background: #2e081e;
  --primary-text-color: rgba(255, 255, 255, 0.9);
  --secondary-text-color: #420a2b;
  --text-background-color: rgba(255, 255, 255, 0.9);
  --navbar-footer-text-color: rgba(255, 255, 255, 0.9);
  --containers-box-shadow: 0px 0px 35px 0px rgb(2, 2, 2);
  --titles-font-family: "Poppins";
  --transition: 0.5s ease;
}

.App {
  /* border: 3px solid red; */
  background-color: var(--general-background);
  transition: var(--transition);
}

/* .content {
  border: 4px solid red;
} */

.theme-changer-button-light {
  color: var(--primary-text-color);

  position: fixed;
  top: 78px;
  right: 16px;
  z-index: 1;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
}

.theme-changer-button-dark {
  color: var(--primary-text-color);

  position: fixed;
  top: 78px;
  right: 16px;
  z-index: 1;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
}

@media screen and (max-width: 975px) {

  .theme-changer-button-light,
  .theme-changer-button-dark {
    top: 62px;
    right: 5px;

  }
}

@media screen and (max-width: 650px) {

  .theme-changer-button-light,
  .theme-changer-button-dark {
    top: 57px;
    right: 1px;
  }
}

@media screen and (max-width: 301px) {

  .theme-changer-button-light,
  .theme-changer-button-dark {
    top: 106px;
    right: 7px;

  }
}

/* ---------------------------- Glowing Navbar Links ---------------------------------*/
/* ---------------------------- Glowing Navbar Links ---------------------------------*/
/* ---------------------------- Glowing Navbar Links ---------------------------------*/

.glow {
  color: white;
  text-align: center;
  text-decoration: underline;
  border-bottom: 3.2px solid white;
}

/* .glow {
  -webkit-animation: glow 1.5s ease-in-out infinite alternate;
  -moz-animation: glow 1.5s ease-in-out infinite alternate;
  animation: glow 1.5s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px rgb(232, 152, 41), 0 0 10px #ff00e6, 0 0 15px #e60073, 0 0 20px #e60073, 0 0 25px #e60073, 0 0 30px #e60073, 0 0 35px #e60073;
  }


  to {
    text-shadow: 0 0 15px rgb(232, 152, 41), 0 0 20px #e817ff, 0 0 25px #ff4da6, 0 0 30px #ff4da6, 0 0 35px #ff4da6, 0 0 40px #ff4da6, 0 0 45px #ff4da6;
  }
} */


/* ---------------------------- Glowing Book Page Release Date ---------------------------------*/
/* ---------------------------- Glowing Book Page Release Date ---------------------------------*/
/* ---------------------------- Glowing Book Page Release Date ---------------------------------*/

.book-page-glow-title,
.book-page-glow-countdown {
  -webkit-animation: glow2 3s ease-in-out infinite alternate;
  -moz-animation: glow2 3s ease-in-out infinite alternate;
  animation: glow2 3s ease-in-out infinite alternate;
}

@keyframes glow2 {
  from {
    text-shadow: 0 0 15px rgb(255, 142, 14), 0 0 15px #fff, 0 0 15px #aff2fb, 0 0 15px #e60073, 0 0 15px #ffc5e2, 0 0 15px #e60073, 0 0 15px #e60073;
  }

  to {
    text-shadow: 0 0 15px rgb(255, 151, 14), 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6;
  }
}

.book-page-glow-questionmark {
  -webkit-animation: glow2-and-rotate 3s ease-in-out infinite alternate;
  -moz-animation: glow2-and-rotate 3s ease-in-out infinite alternate;
  animation: glow2-and-rotate 3s ease-in-out infinite alternate;
}

@keyframes glow2-and-rotate {
  from {
    transform: rotateY(35deg);
    text-shadow: 0 0 15px rgb(255, 142, 14), 0 0 15px #fff, 0 0 15px #aff2fb, 0 0 15px #e60073, 0 0 15px #ffc5e2, 0 0 15px #e60073, 0 0 15px #e60073;
  }

  to {
    transform: rotateY(145deg);
    text-shadow: 0 0 15px rgb(255, 151, 14), 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6, 0 0 15px #ff4da6;
  }
}