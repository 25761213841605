/* -------------------- N A V B A R -------------------- */

.navbar-general-container {
  /* border: 2px solid #ff8e03; */
  display: grid;
  grid-template-columns: 30% 0% 30%;
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  transition: 0.3s ease-in-out;
  background-color: #420a2b;
  padding: 1rem 1.5% 1rem 1.5%;
  z-index: 1;
}

.navbar_fullname_and_socials {
  /* border: 2px solid red; */
  display: flex;
  justify-content: space-between;
}

.navbar-fullname {
  /* border: 1px solid rgb(225, 255, 0); */
  font-size: 21.5px;
  color: var(--navbar-footer-text-color);
}

.navbar_fullname_and_socials a {
  /* border: 1px solid rgb(238, 255, 0); */
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 1px;
  color: var(--navbar-footer-text-color);
  font-size: 18px;
}

.navbar-fullname-link-to-home {
  text-decoration: none;
}

.navbar-links-container {
  /* border: 2px solid rgb(77, 254, 74); */
  display: flex;
  grid-column-start: 3;
  grid-column-end: 4;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-links-container a {
  /* border: 1px solid rgb(238, 255, 0); */
  position: relative;
  color: var(--navbar-footer-text-color);
  font-weight: 600;
  text-decoration: none;
  font-size: 16px;
}

.hide-navbar-up-arrow {
  /* border: 1px solid orange; */
  color: var(--navbar-footer-text-color);
  cursor: pointer;
  display: flex;
  font-size: 20px;
}

.show-navbar-down-arrow {
  /* border: 1px solid white; */
  right: 1.5%;
  top: 15px;
  font-size: 20px;
  color: var(--primary-text-color);
  position: fixed;
  display: flex;
  padding: 3px 0px 3px 0px;
  border-radius: 1px;
  cursor: pointer;
  z-index: 1;
}




.hidden-navbar-general-container {
  position: fixed;
  width: 100%;
  top: -100px;
  display: grid;
  grid-template-columns: 25% 0% 30%;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  background-color: #420a2b;
  padding: 1% 1.5% 1% 1.5%;
  z-index: 1;
}

.hidden-navbar-general-container a {
  color: var(--navbar-footer-text-color);
  font-weight: 600;
  text-decoration: none;
}

.hidden-up-arrow {
  /* border: 1px solid orange; */
  position: relative;
  color: var(--navbar-footer-text-color);
  font-size: 20px;
}





.navbar_fullname_and_socials a:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.170rem;
  background-color: var(--navbar-footer-text-color);
  left: 0;
  bottom: -3px;
  transform: scale(0, 1);
  transform-origin: 0% 100%;
  transition: transform 0.3s ease;
}

.navbar_fullname_and_socials a:hover::after {
  transform: scale(1, 1.4);
}

.navbar-links-container a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.170rem;
  background: #fff2f2;
  left: 0;
  bottom: -3px;
  transform: scale(0, 1);
  transform-origin: 0% 100%;
  transition: transform 0.3s ease;
}

.navbar-links-container a:hover::after {
  transform: scale(1, 1.4);
}

.hidden-down-arrow {
  display: none;
}

/* ------------------------------------------ N A V B A R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ N A V B A R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ N A V B A R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ N A V B A R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ N A V B A R   R E S P O N S I V E N E S S ---------------------------------------------- */

@media screen and (min-width: 1800px) {
  .navbar-fullname {
    font-size: 23px;
  }

  .navbar_fullname_and_socials a {
    font-size: 20px;
  }

  .navbar-links-container a {
    font-size: 18px;
  }
  .hide-navbar-up-arrow {
    font-size: 22px;
  }
  .show-navbar-down-arrow {
    font-size: 22px;
  }
}




@media screen and (max-width: 1250px) {

  .navbar-general-container,
  .hidden-navbar-general-container {
    grid-template-columns: 30% 0% 35%;
  }
}

@media screen and (max-width: 1100px) {

  .navbar-general-container,
  .hidden-navbar-general-container {
    grid-template-columns: 35% 0% 40%;
  }
}

@media screen and (max-width: 975px) {

  .navbar-fullname {
    font-size: 14px;
  }

  .navbar_fullname_and_socials a {
    font-size: 14px;
    padding-top: 1px;
  }

  .navbar-links-container a {
    font-size: 13px;
  }

  .hide-navbar-up-arrow {
    font-size: 16px;
  }
}

@media screen and (max-width: 950px) {

  .navbar-general-container,
  .hidden-navbar-general-container {
    grid-template-columns: 40% 0% 45%;
  }
}


/* when screen width drops below 650.5px, replace right-navbar-links with a dropdown menu. */

@media screen and (max-width: 650.5px) {

  .navbar-general-container,
  .hidden-navbar-general-container {
    /* border: 1px solid red; */
    grid-template-columns: 60% 0% 10%;
  }

  .navbar-dropdown-container {
    /* border: 2px solid rgb(77, 254, 74); */
    display: flex;
    position: relative;
    grid-column-start: 3;
    grid-column-end: 4;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
  }

  .navbar-dropdown-button {
    /* border: 1px solid rgb(195, 255, 0); */
    background: none;
    border: none;
    color: var(--navbar-footer-text-color);
    cursor: pointer;
    font-size: 16px;
    display: flex;
    padding: 0;
    transition: 1s;
  }

  .navbar-dropdown-menu {
    /* border: 1px solid rgb(195, 255, 0); */
    position: absolute;
    right: 0;
    top: calc(100% + .7rem);
    background-color: #420a2b;
    padding: .8rem;
    width: 98px;
    border-radius: .15rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 2);
    transition: 350ms ease-in-out;
  }

  .navbar-dropdown-menu .dropdown-links {
    /* border: 1px solid red; */
    position: relative;
    color: #e1e6fc;
    text-decoration: none;
    font-size: .9em;
  }

  .hidden-navbar-dropdown-menu {
    /* border: 1px solid rgb(195, 255, 0); */
    position: absolute;
    right: 0;
    top: calc(100% + .7rem);
    background-color: #420a2b;
    padding: 0.75rem;
    border-radius: .15rem;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 2);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 500ms ease-in-out;
    transform: translateX(-500px) rotateY(1440deg);
  }

  .hidden-navbar-dropdown-menu .dropdown-links {
    /* border: 1px solid red; */
    position: relative;
    color: #e1e6fc;
    text-decoration: none;
    font-size: .9em;
  }

  .dropdown-links::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.170rem;
    background: #e1e6fc;
    left: 0;
    bottom: -2px;
    transform: scale(0, 1);
    transform-origin: 0% 100%;
    transition: transform 0.3s ease;
  }

  .dropdown-links:hover::after {
    transform: scale(1, 1.4);
  }
}

@media screen and (max-width: 465px) {

  .navbar-general-container,
  .hidden-navbar-general-container {
    grid-template-columns: 85% 0% 10%;
  }
}

@media screen and (max-width: 300px) {

  .navbar-general-container,
  .hidden-navbar-general-container {
    grid-template-columns: 85% 15%;
  }

  .navbar-dropdown-container {
    /* border: 1px solid rgb(77, 254, 74); */
    grid-column-start: 2;
    grid-column-end: 3;
    justify-content: center;
  }

  .navbar-dropdown-button {
    font-size: 1.2rem;
    transition: 0.3s;
  }

  .navbar_fullname_and_socials {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    grid-template-rows: 30px 30px;
    align-items: center;
    text-align: center;
  }

  .navbar-fullname {
    /* border: 1px solid red; */
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 6;
  }

  .navbar_fullname_and_socials a {
    /* border: 1px solid red; */
    grid-row-start: 2;
    grid-row-end: 3;
    justify-content: center;
  }
}