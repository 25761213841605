/* --------------------------------------- S P E S I F I C   G E M S T O N E   P A G E S------------------------------------------ */

.tourmaline {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.tourmaline-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(255, 149, 0), rgb(115, 0, 197), rgb(255, 58, 252), rgb(220, 220, 255), rgb(69, 246, 255), rgb(0, 115, 255), rgb(0, 255, 17), rgb(255, 186, 82), rgb(234, 140, 0)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.tourmaline-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Tourmaline.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 140px;
  width: 15%;
  margin-bottom: 20px;
}

.tourmaline-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.andalusite {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.andalusite-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(0, 255, 242), rgb(134, 255, 255), rgb(106, 47, 47), rgb(174, 255, 255), rgb(74, 246, 255), rgb(115, 55, 55), rgb(79, 255, 246), rgb(45, 255, 230)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.andalusite-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Andalusite.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 120px;
  width: 16%;
  margin-bottom: 20px;
}

.andalusite-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.olivine {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.olivine-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(0, 218, 174), rgb(0, 110, 88), rgb(0, 146, 116), rgb(0, 216, 173), rgb(0, 103, 83), rgb(0, 126, 101), rgb(0, 184, 147), rgb(0, 212, 170)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.olivine-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Olivine.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 125px;
  width: 17%;
  margin-bottom: 20px;
}

.olivine-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.agate {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.agate-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(0, 84, 21), rgb(0, 144, 36), rgb(0, 99, 185), rgb(0, 143, 159), rgb(0, 106, 159), rgb(0, 110, 28)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.agate-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Agate.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 115px;
  width: 12%;
  margin-bottom: 20px;
}

.agate-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.garnet {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.garnet-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgba(100, 4, 4, 0.826), rgba(141, 0, 0, 0.804), rgba(136, 16, 16, 0.766), rgba(159, 18, 18, 0.842), rgba(103, 0, 0, 0.766), rgba(42, 0, 0, 0.738)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 25px;
}

.garnet-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Garnet.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 120px;
  width: 16%;
  margin-bottom: 20px;
}

.garnet-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.lapis {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.lapis-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(0, 15, 232), #3340ff, rgb(252, 248, 28), rgb(47, 61, 255), rgb(0, 15, 218), rgb(68, 80, 255), rgb(0, 15, 232), rgb(0, 14, 215)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 25px;
}

.lapis-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Lapis-Lazuli.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 120px;
  width: 14%;
  margin-bottom: 20px;
}

.lapis-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.feldspar {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.feldspar-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(82, 197, 0), rgb(102, 211, 0), rgb(31, 51, 0), rgb(102, 211, 0), rgb(123, 189, 0), rgb(109, 211, 0), rgb(16, 27, 0)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.feldspar-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Feldspar.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 110px;
  width: 14%;
  margin-bottom: 20px;
}

.feldspar-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.zircon {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.zircon-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(255, 149, 0), rgb(115, 0, 197), rgb(255, 58, 252), rgb(220, 220, 255), rgb(69, 246, 255), rgb(0, 115, 255), rgb(0, 255, 17), rgb(255, 186, 82), rgb(234, 140, 0)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.zircon-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Zircon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 120px;
  width: 13%;
  margin-bottom: 20px;
}

.zircon-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.diamond {
  /* border: 1px solid red; */
  width: 60%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20% 0 20%;
  padding: 15% 0 15% 0;
}

.diamond-title {
  /* border: 1px solid red; */
  font-size: 50px;
  font-weight: 900;
  /* background-image: linear-gradient(to left, rgb(0, 84, 21), rgb(0, 144, 36), rgb(0, 99, 185), rgb(0, 143, 159), rgb(0, 106, 159), rgb(0, 110, 28)); */
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: var(--primary-text-color);
  margin-bottom: 20px;
}

.diamond-img {
  /* border: 1px solid red; */
  position: relative;
  background-image: url("../../../public/images/Diamond.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 110px;
  width: 14%;
  margin-bottom: 20px;
}

.diamond-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  width: 60%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}