/* ---------------------------------- A B O U T   P A G E ------------------------------------------ */

.about-general-container {
  /* border: 5px solid red; */
  display: grid;
  padding: 6rem 9rem 6rem 9rem;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6rem 9rem
}

.about-main-title {
  /* border: 1px solid green; */
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);

  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 900;
  line-height: .9;
}

.about-image {
  /* border: 1px solid green; */
  box-shadow: var(--containers-box-shadow);
  background-image: url("../../../public/images/charis_about.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 85%;
  border-radius: 15px;
  height: 750px;
  max-height: 800px;
}

.about-right-container {
  /* border: 1px solid green; */
  background-color: var(--text-background-color);
  box-shadow: var(--containers-box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 15px;
  padding: 5% 8% 7% 8%;
  text-align: justify;
}

.about-title {
  /* border: 1px solid green; */
  font-family: var(--titles-font-family);
  color: var(--secondary-text-color);
  font-size: 1.35rem;
  font-weight: 600;
  text-align: center;
}

.about-text {
  /* border: 1px solid green; */
  color: var(--secondary-text-color);
  padding-top: 5%;
  font-size: 1.05rem;
  font-weight: 500;
}


/* -------------------------------------- A B O U T   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- A B O U T   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- A B O U T   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- A B O U T   R E S P O N S I V E N E S S ------------------------------------------ */
/* -------------------------------------- A B O U T   R E S P O N S I V E N E S S ------------------------------------------ */

@media screen and (min-width: 1650px) {
  .about-image {
    background-position-y: 62%;
    height: 650px;
  }
}

@media screen and (min-width: 1800px) {
  .about-main-title {
    font-size: 2.35rem;
  }

  .about-title {
    font-size: 1.45rem;
  }

  .about-text {
    font-size: 1.17rem;
  }

  .about-image {
    background-position-y: 62%;
    height: 700px;
  }
}




@media screen and (max-width: 1375px) {
  .about-general-container {
    padding: 6rem 6rem 6rem 6rem;
    grid-gap: 6rem 6rem
  }
}

@media screen and (max-width: 1250px) {
  .about-general-container {
    padding: 6rem 4rem 6rem 4rem;
    grid-gap: 6rem 4rem
  }
}

@media screen and (max-width: 1150px) {
  .about-general-container {
    padding: 6rem 18rem 6rem 18rem;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 6rem 9rem
  }

  .about-main-title {
    grid-column: span 1;
  }

  .about-right-container {
    grid-row-start: 2;
  }
}

@media screen and (max-width: 1100px) {
  .about-general-container {
    padding: 6rem 16rem 6rem 16rem;
    grid-gap: 6rem 9rem
  }
}

@media screen and (max-width: 1000px) {
  .about-general-container {
    padding: 6rem 13rem 6rem 13rem;
    grid-gap: 6rem 9rem
  }
}

@media screen and (max-width: 975px) {
  .about-general-container {
    padding: 4.5rem 9rem 4.5rem 9rem;
    grid-gap: 4.5rem 9rem
  }

  .about-main-title {
    font-size: 1.6rem;
  }

  .about-title {
    font-size: 1.03rem;
  }

  .about-text {
    font-size: .95rem;
  }
}


@media screen and (max-width: 900px) {
  .about-general-container {
    padding: 4.5rem 10rem 4.5rem 10rem;
    grid-gap: 4.5rem 9rem
  }
}

@media screen and (max-width: 750px) {
  .about-general-container {
    padding: 4.5rem 7rem 4.5rem 7rem;
    grid-gap: 4.5rem 9rem
  }
}

@media screen and (max-width: 650px) {
  .about-general-container {
    padding: 4.5rem 3rem 4.5rem 3rem;
    grid-gap: 4.5rem 9rem
  }
}

@media screen and (max-width: 530px) {
  .about-general-container {
    padding: 3.5rem 2rem 3.5rem 2rem;
    grid-gap: 3.5rem 9rem
  }
}

@media screen and (max-width: 450px) {
  .about-image {
    background-position-x: 100%;
  }
}

@media screen and (max-width: 400px) {
  .about-general-container {
    padding: 3rem 2rem 3rem 2rem;
    grid-gap: 3rem 9rem
  }

  .about-main-title {
    font-size: 1.3rem;
  }

  .about-title {
    font-size: .95rem;
  }

  .about-text {
    font-size: .85rem;
  }

  .about-image {
    max-height: 400px;
  }

}

@media screen and (max-width: 300px) {
  .about-image {
    max-height: 280px;
  }
}

@media screen and (max-width: 250px) {
  .about-image {
    max-height: 220px;
  }
}