/* -------------------- H O M E   P A G E ---------------------  */

.home-general-container {
  /* border: 5px solid red; */
  position: relative;
  padding: 6rem 6rem 6rem 6rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 6rem 6rem;
}

.home1-main-title {
  margin-bottom: 0rem;
  line-height: .9;
}

.home1-main-title {
  /* border: 1px solid green; */
  grid-column: span 2;
  font-size: 2.2rem;
  font-weight: 900;
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.home2-container {
  /* border: 1px solid green; */
  text-align: center;
}

.home2-title {
  font-family: var(--titles-font-family);
  color: var(--primary-text-color);
  font-size: 1.35rem;
  margin-bottom: 10px;
}

.home2-text {
  color: var(--primary-text-color);
  font-weight: 600;
  font-size: 1.05rem;
  padding: 5px 0px 35px 0px;
  text-align: justify;
}

.home2-button {
  /* border: 1px solid #220617; */
  text-decoration: none;
  font-size: 1.05rem;
  font-weight: 600;
  color: var(--primary-text-color);
  border-radius: 4px;
  padding: 3px;
}

.home2-button:hover {
  text-decoration: underline;
  transition: 0.3s ease;
}

.home3-container {
  /* border: 1px solid green; */
  width: 100%;
  height: 350px;
  max-height: 355px;
  background-image: url("../../../public/images/book-cover.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 0;
}

.home4-container {
  /* border: 1px solid green; */
  height: 355px;
  max-height: 355px;
  background-image: url("../../../public/images/charis-homepage-final.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  clip-path: circle(35%);
}

.home5-container {
  /* border: 1px solid green; */
  text-align: center;
  font-weight: 600;
}

.home5-title {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  font-family: var(--titles-font-family);
  margin-bottom: 10px;
  font-size: 1.35rem;
  font-weight: 900;
}

.home5-text {
  /* border: 1px solid red; */
  color: var(--primary-text-color);
  margin-bottom: 35px;
  font-size: 1.05rem;
  text-align: justify;
}

.home5-button {
  /* border: 1px solid #430c2d; */
  color: var(--primary-text-color);
  text-decoration: none;
  font-size: 1.05rem;
  border-radius: 4px;
  padding: 3px;
}

.home5-button:hover {
  text-decoration: underline;
  transition: 0.3s ease;
}

.home6-container {
  /* border: 1px solid green; */
  grid-column: span 2;
  text-align: center;
  font-weight: 600;
}

.home6-title {
  color: var(--primary-text-color);
  font-family: var(--titles-font-family);
  font-size: 1.35rem;
  font-weight: 900;
  margin-bottom: 25px;
}

.home6-text {
  color: var(--primary-text-color);
  font-size: 1.05rem;
  margin-bottom: 25px;
}

.home6-button {
  color: var(--primary-text-color);
  text-decoration: none;
  font-size: 1.05rem;
  border-radius: 4px;
  padding: 3px;
}

.home6-button:hover {
  text-decoration: underline;
  transition: 0.3s ease;
}


/* ------------------------------------------ H O M E   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ H O M E   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ H O M E   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ H O M E   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ H O M E   R E S P O N S I V E N E S S ---------------------------------------------- */

@media screen and (min-width: 1540px) {
  .home4-container {
    clip-path: circle(32%);
  }
}

@media screen and (min-width: 1680px) {
  .home4-container {
    clip-path: circle(30%);
  }
}

@media screen and (min-width: 1800px) {
  .home1-main-title {
    font-size: 2.35rem;
  }

  .home2-title {
    font-size: 1.50rem;
  }

  .home2-text {
    font-size: 1.20rem;
  }

  .home2-button {
    font-size: 1.20rem;
  }

  .home5-title {
    font-size: 1.50rem;
  }

  .home5-text {
    font-size: 1.20rem;
  }

  .home5-button {
    font-size: 1.20rem;
  }

  .home6-title {
    font-size: 1.50rem;
  }

  .home6-text {
    font-size: 1.20rem;
  }

  .home6-button {
    font-size: 1.20rem;
  }
}

@media screen and (min-width: 1800px) {
  .home4-container {
    clip-path: circle(28%);
  }
}

@media screen and (min-width: 1921px) {
  .home4-container {
    clip-path: circle(27%);
  }
}

@media screen and (min-width: 1601px) {

  .gemstone1,
  .gemstone2,
  .gemstone3,
  .gemstone4,
  .gemstone5,
  .gemstone6,
  .gemstone7,
  .gemstone8,
  .gemstone9 {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }

  .home6-text {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
}

@media screen and (min-width: 1536px) and (max-width: 1600px) {
  .gemstone1 {
    position: absolute;
    background-image: url("../../../public/images/Tourmaline.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 0px;
    opacity: 0;

    animation-name: gemstone1;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone1 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone1 {
    0% {
      left: -100px;
      top: -100px;
      opacity: 1;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: 20px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: 1220px;
      top: 400px;
      height: 85px;
      width: 6%;
    }
  }

  .gemstone1:hover {
    opacity: 1 !important;
    height: 70px;
    width: 6.5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone2 {
    position: absolute;
    background-image: url("../../../public/images/Andalusite.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 1400px;
    opacity: 0;

    animation-name: gemstone2;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone2 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone2 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -1380px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: -1300px;
      top: 410px;
      height: 80px;
      width: 6%;
    }
  }

  .gemstone2:hover {
    opacity: 1 !important;
    height: 70px;
    width: 5.5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone3 {
    position: absolute;
    background-image: url("../../../public/images/Olivine.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 250px;
    z-index: 0;
    opacity: 0;

    animation-name: gemstone3;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone3 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone3 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -230px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: 550px;
      top: 780px;
      height: 105px;
      width: 5%;
    }
  }

  .gemstone3:hover {
    opacity: 1 !important;
    height: 75px;
    width: 5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone4 {
    position: absolute;
    background-image: url("../../../public/images/Agate.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 750px;
    opacity: 0;

    animation-name: gemstone4;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone4 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone4 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -720px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: -650px;
      top: 950px;
      height: 80px;
      width: 5%;
    }
  }

  .gemstone4:hover {
    opacity: 1 !important;
    height: 70px;
    width: 4.5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone5 {
    position: absolute;
    background-image: url("../../../public/images/Garnet.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 850px;
    opacity: 0;

    animation-name: gemstone5;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone5 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone5 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -820px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: -650px;
      top: 1400px;
      height: 95px;
      width: 5.5%;
    }
  }

  .gemstone5:hover {
    opacity: 1 !important;
    height: 90px;
    width: 5.5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone6 {
    position: absolute;
    background-image: url("../../../public/images/Lapis-Lazuli.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 550px;
    opacity: 0;

    animation-name: gemstone6;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone6 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone6 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -525px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: 770px;
      top: 850px;
      height: 85px;
      width: 5.8%;
    }
  }

  .gemstone6:hover {
    opacity: 1 !important;
    height: 100px;
    width: 5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone7 {
    position: absolute;
    background-image: url("../../../public/images/Feldspar.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 550px;
    opacity: 0;

    animation-name: gemstone7;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone7 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone7 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -525px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: 150px;
      top: 1250px;
      height: 85px;
      width: 4.6%;
    }
  }

  .gemstone7:hover {
    opacity: 1 !important;
    height: 65px;
    width: 5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone8 {
    position: absolute;
    background-image: url("../../../public/images/Zircon.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 550px;
    opacity: 0;

    animation-name: gemstone8;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone8 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone8 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -525px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: 800px;
      top: 1250px;
      height: 90px;
      width: 5%;
    }
  }

  .gemstone8:hover {
    opacity: 1 !important;
    height: 108px;
    width: 4.5%;
    transition: 0.15s ease;
    cursor: pointer;
  }

  .gemstone9 {
    position: absolute;
    background-image: url("../../../public/images/Diamond.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 0px;
    width: 0px;
    margin-top: -300px;
    margin-left: 750px;
    opacity: 0;

    animation-name: gemstone9;
    animation-duration: 2s;
    animation-delay: 0.1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }

  .gemstone9 a {
    text-decoration: none;
    font-size: 43px;
    border-bottom: 11px solid red;
    opacity: 0;
  }

  @keyframes gemstone9 {
    0% {
      opacity: 1;
      left: -100px;
      top: -100px;
    }

    50% {
      transform: rotateZ(3600deg);
      opacity: 1;
      left: -720px;
      top: 320px;
    }

    100% {
      opacity: 0.2;
      left: 350px;
      top: 1500px;
      height: 80px;
      width: 6%;
    }
  }

  .gemstone9:hover {
    opacity: 1 !important;
    height: 70px;
    width: 4.5%;
    transition: 0.15s ease;
    cursor: pointer;
  }
}

@media screen and (max-width: 1535px) {

  .gemstone1,
  .gemstone2,
  .gemstone3,
  .gemstone4,
  .gemstone5,
  .gemstone6,
  .gemstone7,
  .gemstone8,
  .gemstone9 {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }

  .home6-text {
    display: none;
    visibility: hidden;
    pointer-events: none;
  }
}

@media screen and (max-width: 1400px) {
  .home4-container {
    clip-path: circle(38%);
  }
}

@media screen and (max-width: 1275px) {
  .home4-container {
    clip-path: circle(41%);
  }
}

@media screen and (max-width: 1150px) {
  .home4-container {
    clip-path: circle(45%);
  }
}

@media screen and (max-width: 975px) {
  .home-general-container {
    padding: 4.5rem 4.5rem 4.5rem 4.5rem;
    grid-gap: 4.5rem 4.5rem;
  }

  .home1-main-title {
    font-size: 1.6rem;
  }

  .home2-title,
  .home5-title,
  .home6-title {
    font-size: 1.03rem;
  }

  .home2-text,
  .home5-text,
  .home6-text {
    font-size: .95rem;
  }

  .home2-button,
  .home5-button,
  .home6-button {
    font-size: 1rem;
  }
}

@media screen and (max-width: 860px) {
  .home4-container {
    clip-path: circle(41%);
  }
}

@media screen and (max-width: 750px) {
  .home-general-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .home1-main-title,
  .home2-container,
  .home3-container,
  .home4-container,
  .home5-container,
  .home6-container {
    grid-column: span 1;
  }

  .home4-container {
    clip-path: inset(0% 0%);

  }
}

@media screen and (max-width: 530px) {
  .home-general-container {
    padding: 3.5rem 2rem 3.5rem 2rem;
    grid-gap: 3.5rem 9rem
  }
}

@media screen and (max-width: 400px) {
  .home-general-container {
    padding: 3rem 2rem 3rem 2rem;
    grid-gap: 3rem 4rem;
  }

  .home1-main-title {
    font-size: 1.3rem;
  }

  .home2-title,
  .home5-title,
  .home6-title {
    font-size: .95rem;
  }

  .home2-text,
  .home5-text,
  .home6-text {
    font-size: .85rem;
  }

  .home2-button,
  .home5-button,
  .home6-button {
    font-size: .85rem;
  }
}

@media screen and (max-width: 350px) {

  .home1-main-title {
    font-size: 1.1rem;
  }

  .home2-title,
  .home5-title,
  .home6-title {
    font-size: .85rem;
  }

  .home2-text,
  .home5-text,
  .home6-text {
    font-size: .75rem;
  }

  .home2-button,
  .home5-button,
  .home6-button {
    font-size: .75rem;
  }
}