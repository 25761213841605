/* -------------------- F O O T E R -------------------- */

.footer-image {
  /* border: 2px solid rgb(51, 255, 0); */
  background-image: url("../../public/images/black5.png");
  background-size: cover;
  background-position: bottom;
  padding: 200px 0px 0px 0px;
}

.inner-footer-container {
  /* border: 3px solid red; */
  display: grid;
  grid-template-columns: 30% 40% 30%;
  color: var(--navbar-footer-text-color);
  font-weight: 600;
  text-shadow: 0px 0px 6px black;
  padding: 0 1.5% 1.5% 1.5%;
}

/* .footer-left-newsletter-wrapper {
  border: 2px solid rgb(242, 128, 255);
} */
.newsletter-error-message {
  border: 1px solid red;
  color: var(--secondary-text-color);
  position: relative;
  font-size: 15px;
  border-radius: 3px;
  /* padding: 10px; */
  margin-top: 10px;
}

.newsletter-error-message-hidden {
  border: 1px solid red;
  color: var(--secondary-text-color);
  position: relative;
  font-size: 15px;
  border-radius: 3px;
  height: 20px;
  margin-top: 10px;
  visibility: hidden;
}

.footer-newsletter-title {
  /* border: 1px solid red; */
  margin-bottom: 10px;
  width: 60%;
  font-size: 18px;
  font-weight: bolder;
  text-decoration: underline;
}

.footer-newsletter-text {
  /* border: 1px solid red; */
  width: 70%;
  font-size: 16px;
  margin-bottom: 15px;
}

.footer-input-newsletter-username {
  width: 61%;
  font-size: 16px;
  margin-bottom: 5px;
  border: none;
  border-radius: 5px;
  padding: 3px 0px 3px 5px;
  font-weight: 600;
  color: #420a2b;
}

::placeholder {
  color: #420a2b;
}

.footer-input-newsletter-email {
  width: 61%;
  font-size: 16px;
  margin-bottom: 5px;
  border: none;
  border-radius: 5px;
  padding: 3px 0px 3px 5px;
  font-weight: 600;
  color: #420a2b;
}

.newsletter-sign-up-button {
  font-size: 16px;
  width: 61%;
  height: 35px;
  border: 3px solid transparent;
  background-color: white;
  border-radius: 5px;
  font-weight: 700;
  color: #420a2b;
}

.newsletter-sign-up-button:hover {
  font-size: 20px;
  padding-bottom: 5px;
  border-radius: 25px;
  cursor: pointer;
  color: #ffffff;
  background-color: #420a2b;
  transition: ease 0.3s;
}

.footer-mid-rights-wrapper {
  /* border: 2px solid rgb(187, 255, 0); */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding-bottom: 1%;
}

.footer-author-socials {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  width: 35%;
  margin-bottom: 2%;
  color: black;
}

.footer-mid-rights {
  /* border: 1px solid red; */
  font-size: 14px;
}

.footer-right-contact-wrapper {
  /* border: 2px solid rgb(85, 255, 255); */
  display: flex;
  flex-direction: column;
  text-align: right;
}

.footer-connect-title {
  /* border: 1px solid red; */
  font-size: 18px;
  margin-bottom: 25px;
  text-decoration: underline;
  font-weight: bolder;
}

.footer-author-container {
  /* border: 1px solid red; */
  margin-bottom: 25px;
}

.footer-author-name {
  font-size: 16px;
  margin-bottom: 5px;
}

.footer-author-email {
  font-size: 16px;
  color: var(--navbar-footer-text-color);
  text-decoration: none;
}

.footer-author-socials a {
  font-size: 16px;
  color: white;
}

.footer-author-email:hover,
.footer-author-socials a:hover {
  color: #a1286f;
  text-decoration: underline;
  transition: 0.3s ease;
}

/* .footer-webdev-container {
  border: 1px solid red;
} */

.footer-webdev-name {
  margin-bottom: 5px;
  font-size: 16px;
  color: var(--navbar-footer-text-color);
}

.footer-webdev-email {
  margin-bottom: 5px;
}

.footer-webdev-socials {
  /* border: 1px solid rgb(57, 255, 35); */
  display: flex;
  justify-content: right;
}

.footer-webdev-email a,
.footer-webdev-socials a {
  font-size: 16px;
  color: var(--navbar-footer-text-color);
  text-decoration: none;
}

.footer-webdev-socials a {
  margin-left: 5px;
}

.footer-webdev-email a:hover,
.footer-webdev-socials a:hover {
  color: #d0539a;
  text-decoration: underline;
  transition: 0.3s ease;
}


/* ------------------------------------------ F O O T E R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ F O O T E R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ F O O T E R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ F O O T E R   R E S P O N S I V E N E S S ---------------------------------------------- */
/* ------------------------------------------ F O O T E R   R E S P O N S I V E N E S S ---------------------------------------------- */


@media screen and (min-width: 1800px) {
  .footer-newsletter-title {
    font-size: 20px;
  }

  .footer-newsletter-text {
    font-size: 18px;
  }

  .footer-input-newsletter-username {
    font-size: 18px;
  }

  .footer-input-newsletter-email {
    font-size: 18px;
  }

  .newsletter-sign-up-button {
    font-size: 18px;
  }

  .newsletter-sign-up-button:hover {
    font-size: 22px;
  }

  .footer-mid-rights {
    font-size: 15px;
  }

  .footer-connect-title {
    font-size: 20px;
  }

  .footer-author-name {
    font-size: 18px;
  }

  .footer-author-email {
    font-size: 18px;
  }

  .footer-webdev-name {
    font-size: 18px;
  }

  .footer-webdev-email a,
  .footer-webdev-socials {
    font-size: 18px;
  }
}


@media screen and (min-width: 1650px) {
  .footer-image {
    padding: 290px 0px 0px 0px;
  }
}

@media screen and (max-width: 1240px) {

  .footer-newsletter-title {
    width: 70%;
  }

  .footer-newsletter-text {
    width: 70%;
  }

  .footer-input-newsletter-username {
    width: 70%;
  }

  .footer-input-newsletter-email {
    width: 70%;
  }

  .newsletter-sign-up-button {
    width: 70%;
  }

  .newsletter-sign-up-button:hover {
    width: 70%;
  }

  .footer-author-socials {
    width: 45%;
  }

  .footer-right-contact-wrapper {
    padding: 0px 10% 10% 25%;
  }

}

@media screen and (max-width: 1050px) {
  .footer-newsletter-title {
    width: 80%;
  }

  .footer-newsletter-text {
    width: 80%;
  }

  .footer-input-newsletter-username {
    width: 80%;
  }

  .footer-input-newsletter-email {
    width: 80%;
  }

  .newsletter-sign-up-button {
    width: 80%;
  }

  .newsletter-sign-up-button:hover {
    width: 80%;
  }

  .footer-author-socials {
    width: 55%;
  }

  .footer-right-contact-wrapper {
    padding: 0px 10% 10% 15%;
  }
}

@media screen and (max-width: 950px) {

  .footer-left-newsletter-wrapper {
    padding: 0px 0px 5% 5%;
  }

  .footer-newsletter-title,
  .footer-newsletter-text,
  .footer-input-newsletter-username,
  .footer-input-newsletter-email,
  .newsletter-sign-up-button,
  .newsletter-sign-up-button:hover {
    width: 85%;
  }

  .footer-right-contact-wrapper {
    padding: 0px 5% 10% 5%;
  }
}

@media screen and (max-width: 800px) {
  .footer-image {
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0px;
  }

  .newsletter-error-message {
    color: var(--primary-text-color);
    border: 2px solid red;
    padding: 3px;
  }

  .inner-footer-container {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: 2em;
  }

  .footer-left-newsletter-wrapper {
    /* border: 1px solid rgb(0, 255, 234); */
    text-align: center;
    padding: 3%;
    text-shadow: none;
  }

  .footer-left-newsletter-wrapper form {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-input-newsletter-username {
    border: 1px solid #420a2b;
  }

  .footer-input-newsletter-email {
    border: 1px solid #420a2b;
  }

  .newsletter-sign-up-button {
    border: 1px solid #420a2b;
  }

  .footer-right-contact-wrapper {
    /* border: 1px solid red; */
    color: var(--primary-text-color);
    padding: 3% 3% 25% 3%;
    text-align: center;
    text-shadow: none;
  }

  .footer-author-email {
    color: var(--primary-text-color);
  }

  .footer-webdev-name,
  .footer-webdev-email a,
  .footer-webdev-socials a {
    color: var(--primary-text-color);
  }

  .footer-webdev-socials {

    justify-content: center;
  }

  .footer-newsletter-title {
    width: 100%;
    color: var(--primary-text-color);
  }

  .footer-newsletter-text {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px 30% 0px 30%;
    color: var(--primary-text-color);
  }

  .footer-input-newsletter-username,
  .footer-input-newsletter-email,
  .newsletter-sign-up-button,
  .newsletter-sign-up-button:hover {
    width: 35%;
  }

  .footer-mid-rights-wrapper {
    /* border: 2px solid red; */
    grid-row-start: 3;
    grid-row-end: 4;
  }

  .footer-author-socials {
    width: 35%;
  }

  .footer-connect-title {
    margin-bottom: 15px;
  }

  .footer-author-container {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 600px) {
  .footer-image {
    background-size: 165%;
    background-repeat: no-repeat;
    padding: 0px 0px 0px 0px;
  }

  .footer-newsletter-text {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px 20% 0px 20%;
  }

  .footer-input-newsletter-username,
  .footer-input-newsletter-email,
  .newsletter-sign-up-button,
  .newsletter-sign-up-button:hover {
    width: 50%;
  }

  .footer-author-socials {
    width: 50%;
  }

  .footer-mid-rights {
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .footer-image {
    background-size: 200%;
  }

  .footer-newsletter-text {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px 10% 0px 10%;
  }

  .footer-input-newsletter-username,
  .footer-input-newsletter-email,
  .newsletter-sign-up-button,
  .newsletter-sign-up-button:hover {
    width: 70%;
  }

  .footer-author-socials {
    width: 50%;
  }
}

@media screen and (max-width: 350px) {
  .footer-image {
    background-size: 220%;
  }
}

@media screen and (max-width: 300px) {
  .footer-image {
    background-size: 250%;
  }

  .footer-newsletter-text {
    /* border: 1px solid red; */
    width: 100%;
    padding: 0px 10% 0px 10%;
  }

  .footer-input-newsletter-username,
  .footer-input-newsletter-email,
  .newsletter-sign-up-button,
  .newsletter-sign-up-button:hover {
    width: 80%;
  }

  .footer-author-socials {
    width: 75%;
  }
}

@media screen and (max-width: 245px) {
  .footer-image {
    background-size: 305%;
  }
}

@media screen and (max-width: 220px) {

  .footer-newsletter-title,
  .footer-connect-title {
    font-size: 15px;
  }

  .footer-newsletter-text,
  .footer-input-newsletter-username,
  .footer-input-newsletter-email,
  .newsletter-sign-up-button,
  .footer-author-name,
  .footer-author-email,
  .footer-webdev-name,
  .footer-webdev-email a,
  .footer-webdev-socials {
    font-size: 13px;
  }

  .newsletter-sign-up-button:hover {
    font-size: 15px;
  }
}